import styled from "styled-components";
import { StatusProp } from "../../../types/types";

export const ButtonWrap = styled.div`
  padding-left: 20%;
`;
export const Main = styled.div`
  //padding-right: 2.5rem;
  width: 100%;
  padding-bottom: 2%;
  border-radius: 1rem;
`;
export const TableDiv = styled.div`
  width: 100%;
  //margin-left: -0.7rem;
`;
export const Container = styled.div`
  border-radius: 20px;
  background: #fff;
  padding: 30px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 30px;
`;
export const HeaderText = styled.h2`
  padding-left: 1rem;
  padding-bottom: 2.5rem;
`;
export const Wrap = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 3%;
`;
export const TableContain = styled.div`
  margin-top: -4rem;
  // padding-left: 1rem;
`;
export const ButtonDiv = styled.div`
  padding-left: 1rem;
  margin-top: 0.5rem;
`;
export const Status = styled.div<StatusProp>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  text-align: center;
  border-radius: 12px;
  padding: 4px 8px;
`;

export const ActionDiv = styled.div`
  display: flex;
  justify-content: center;
`;
