import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";
import { ReactComponent as EllipseIcon } from "../../assets/svg/el.svg";
import { SidebarProps } from "../../types/types";
import {
  LinkWrap,
  MainTitle,
  DropIcon,
  DropdownLink,
  SidebarLabel,
  SidebarLabelSub,
  DropContainer,
} from "./style";

interface ItemProps {
  item: SidebarProps;
  expanded: any;
}
const SubMenu: React.FC<ItemProps> = ({ item, expanded }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <NavLink
        to={item.path}
        onClick={item.subNav && showSubnav}
        style={({ isActive }) =>
          isActive
            ? {
                display: "flex",
                alignItems: "center",
                width: "100%",
                borderRadius: "10px",
                padding: "3px 0px",
                color: "#5C2684",
                justifyContent: "space-between",
                backgroundColor: "#d7cbe2",
                textDecoration: "none",
              }
            : {
                color: "#464347",
                display: "flex",
                alignItems: "center",
                padding: "3px 0px",
                justifyContent: "space-between",
                borderRadius: "10px",
                width: "100%",
                textDecoration: "none",
              }
        }
      >
        <LinkWrap>
          <MainTitle>
            <item.icon size={20} />
            <SidebarLabel>{expanded ? item.title : null}</SidebarLabel>
          </MainTitle>
          <DropIcon>
            {item.subNav && (
              <>
                {subnav ? (
                  <HiChevronUp color="#807984" size={18} />
                ) : (
                  <HiChevronDown color="#807984" size={18} />
                )}
              </>
            )}
          </DropIcon>
        </LinkWrap>
      </NavLink>
      {subnav &&
        item?.subNav?.map((item: SidebarProps) => {
          return (
            <DropdownLink to={item.path} key={item.title}>
              <EllipseIcon />
              <SidebarLabelSub>{expanded ? item.title : null}</SidebarLabelSub>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
