import React, { useEffect, useMemo, useState } from "react";
import {
  TableBody,
  TableBodyData,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderTitle,
  TablePaginatorWrapper,
  TableWrapper,
} from "./style";
import { v4 as uuidv4 } from "uuid";
import Paginator from "../Paginator";
interface HeaderData {
  title: string;
  id: number;
}

export interface TableCProps {
  header: HeaderData[];
  body: any[][] | undefined;
  arrOfObject: boolean;
  itemPaginateLimit?: any;
}

const PrimaryTable: React.FC<TableCProps> = ({
  header,
  body,
  arrOfObject,
  itemPaginateLimit,
}) => {
  const [currentItems, setCurrentItems] = useState<any[][] | undefined>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  const dataBody: any[][] = useMemo(() => {
    return body ?? [];
  }, [body]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    dataBody?.length > itemsPerPage
      ? setCurrentItems(dataBody?.slice(itemOffset, endOffset))
      : setCurrentItems(dataBody);
    setPageCount(Math.ceil(dataBody?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, pageCount, dataBody]);

  const handlePageClick = (e: any) => {
    const newOffset = e.selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  return (
    <TableContainer>
      <TableWrapper>
        <TableHeader>
          <TableHeaderRow>
            {header?.map((data: { title: string; id: number }) => (
              <TableHeaderTitle key={data.id}>{data.title}</TableHeaderTitle>
            ))}
          </TableHeaderRow>
        </TableHeader>

        <TableBody>
          {currentItems?.map((text) => {
            return (
              <TableBodyRow key={uuidv4()}>
                {Object.values(text).map((value, index) => (
                  <TableBodyData key={uuidv4()}>{value}</TableBodyData>
                ))}
              </TableBodyRow>
            );
          })}
        </TableBody>
      </TableWrapper>

      {dataBody?.length > 10 && (
        <TablePaginatorWrapper>
          <Paginator handlePageClick={handlePageClick} pageCount={pageCount} />
        </TablePaginatorWrapper>
      )}
    </TableContainer>
  );
};

export default PrimaryTable;
