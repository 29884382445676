// dataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const reportDataSlice = createSlice({
  name: "data",
  initialState: {
    data: [], // Initialize with an empty array
    loading: "false", // Use RTK Query's loading state
    error: null,
    table: false,
  },
  reducers: {
    setReportData: (state, action) => {
      state.data = action.payload;
    },
    setPersistedTable: (state, action) => {
      state.table = action.payload;
    },
    resetPersistedTable: (state) => {
      state.table = false;
    },
    resetSlice: (state) => {
      // Reset the slice to its initial state
      state.data = [];
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setReportData,
  setError,
  resetSlice,
  resetPersistedTable,
  setPersistedTable,
} = reportDataSlice.actions;

export default reportDataSlice.reducer;
