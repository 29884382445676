import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorFormatter } from "../../../utils/errorFormatter";
import toast from "react-hot-toast";

const localInfo = localStorage.getItem("staffInfo");
const tokenValue = JSON.parse(localInfo ?? "{}")?.accessToken;

const BASE_URL = process.env.REACT_APP_BASE_URL;

const END_POINT = `transactions/initiate`;
export const initiateTransaction = createAsyncThunk<any, any>(
  "data/initiateData",
  async (payload, { rejectWithValue }) => {
    try {
      const headers = {
        Authorization: `Bearer ${tokenValue}`,
      };
      const response = await axios.post(`${BASE_URL}${END_POINT}`, payload, {
        headers,
      });
      toast.success(response.data?.responseDescription);
      if (response?.data?.statusCode === 401) {
        localStorage.clear();
        window.location.href = "/login";
      }
      return response.data;
    } catch (err: any) {
      toast.error(err?.response?.data?.statusText);
      return rejectWithValue(err);
    }
  }
);

// slice fot posting data upon initiated transaction
const initiateTransactionDetails = createSlice({
  name: "initiate",
  initialState: {
    isLoading: false,
    error: "no error",
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initiateTransaction.pending, (state) => {
        state.isLoading = true;
        state.error = "still checking";
        state.data = null;
      })
      .addCase(initiateTransaction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "no error";
        state.data = action.payload;
      })
      .addCase(initiateTransaction.rejected, (state: any, action) => {
        const message = errorFormatter(action.payload);
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default initiateTransactionDetails.reducer;
