import { useMemo, useState } from "react";
import {
  AdminSideBarButtons,
  TellerSidebarData,
  AMUSidebarButtons,
  AMUSupervisorSideButtons,
  SupervisorData,
  ClearingSupervisorData,
  ClearingData,
  SuperUserSideBarButtons,
} from "./data";
import SubMenu from "./subMenu";
import { ReactComponent as FCMBIcon } from "../../assets/svg/fcmb.svg";
import {
  MainContainer,
  ListWrapper,
  LogoWrapper,
  SidebarNav,
  SidebarWrap,
  SidebarParentWrapper,
  LogoutWrapper,
  IconButtons,
  ButtonTexts,
} from "./style";
import {
  HiOutlineLogout,
  HiOutlineChevronRight,
  HiOutlineChevronLeft,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { IconContext } from "../../../node_modules/react-icons/lib/esm/iconContext";
import { SidebarProps } from "../../types/types";
import { useSelector } from "react-redux";
import { useLogoutMutation } from "../../services/auth/authService";
import { handleLogout } from "../../utils/logout";

const Sidebar = () => {
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const [expanded, setExpaned] = useState(() => window.innerWidth > 1050);

  const memoizedIconContextValue = useMemo(
    () => ({ className: "sidebar-icons" }),
    []
  );
  const staffInfo = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo
  );

  return (
    <MainContainer width={expanded ? "280px" : "100px"}>
      <IconContext.Provider value={memoizedIconContextValue}>
        <LogoWrapper justify={expanded ? " space-between" : "center"}>
          {expanded && <FCMBIcon />}

          <ListWrapper onClick={() => setExpaned(!expanded)}>
            {expanded ? (
              <HiOutlineChevronLeft color="white" size={20} />
            ) : (
              <HiOutlineChevronRight color="white" size={20} />
            )}
          </ListWrapper>
        </LogoWrapper>

        <SidebarNav>
          <SidebarWrap>
            {/* clearing staff */}
            {staffInfo?.user?.roleName?.toLowerCase() === "clearing staff" && (
              <>
                {ClearingData.map((item: SidebarProps) => {
                  return (
                    <SubMenu item={item} key={item.title} expanded={expanded} />
                  );
                })}
              </>
            )}

            {/* clearing supervisor */}
            {staffInfo?.user?.roleName?.toLowerCase() ===
              "clearing supervisor" && (
              <>
                {ClearingSupervisorData.map((item: SidebarProps) => {
                  return (
                    <SubMenu item={item} key={item.title} expanded={expanded} />
                  );
                })}
              </>
            )}

            {/* tb ops supervisor and csm*/}
            {(staffInfo?.user?.roleName?.toLowerCase() ===
              "tb ops supervisor" ||
              staffInfo?.user?.roleName?.toLowerCase() === "csm") && (
              <>
                {SupervisorData.map((item: SidebarProps) => {
                  return (
                    <SubMenu item={item} key={item.title} expanded={expanded} />
                  );
                })}
              </>
            )}

            {/* AMU supervisor */}
            {staffInfo?.user?.roleName?.toLowerCase() === "amu supervisor" && (
              <>
                {AMUSupervisorSideButtons.map((item: SidebarProps) => {
                  return (
                    <SubMenu item={item} key={item.title} expanded={expanded} />
                  );
                })}
              </>
            )}

            {/* amu maker and makter*/}
            {(staffInfo?.user?.roleName?.toLowerCase() === "amu maker" ||
              staffInfo?.user?.roleName?.toLowerCase() === "maker") && (
              <>
                {AMUSidebarButtons.map((item: SidebarProps) => {
                  return (
                    <SubMenu item={item} key={item.title} expanded={expanded} />
                  );
                })}
              </>
            )}

            {/* tb ops staff and head teller*/}
            {(staffInfo?.user?.roleName?.toLowerCase() === "tb ops staff" ||
              staffInfo?.user?.roleName?.toLowerCase() === "head teller") && (
              <>
                {TellerSidebarData.map((item: SidebarProps) => {
                  return (
                    <SubMenu item={item} key={item.title} expanded={expanded} />
                  );
                })}
              </>
            )}

            {/* admin and support team*/}
            {(staffInfo?.user?.roleName?.toLowerCase() === "admin" ||
              staffInfo?.user?.roleName?.toLowerCase() === "support team") && (
              <>
                {AdminSideBarButtons.map((item: SidebarProps) => {
                  return (
                    <SubMenu item={item} key={item.title} expanded={expanded} />
                  );
                })}
              </>
            )}

            {staffInfo?.user?.roleName?.toLowerCase() === "superuser" && (
              <>
                {SuperUserSideBarButtons.map((item: SidebarProps) => {
                  return (
                    <SubMenu item={item} key={item.title} expanded={expanded} />
                  );
                })}
              </>
            )}

            <SidebarParentWrapper>
              <LogoutWrapper onClick={() => handleLogout(logout, navigate)}>
                <IconButtons>
                  <HiOutlineLogout color={"#464347"} size={20} />
                  {expanded && (
                    <ButtonTexts color={"#464347"}>Logout</ButtonTexts>
                  )}
                </IconButtons>
              </LogoutWrapper>
            </SidebarParentWrapper>
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </MainContainer>
  );
};

export default Sidebar;
