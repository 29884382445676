import Sidebar from "../components/StaffSidebar";
import { LayoutProps } from "../types/types";
import { Outlet } from "react-router-dom";
import { Body, BodyLeft, BodyRight, Dashboard } from "./style";
import { useIdleTimer } from "react-idle-timer";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Overview from "../components/Overview";

const Layout = ({ children }: LayoutProps) => {
  const notify = () => {
    toast("You have been automatically logged out of the system", {
      style: {
        background: "#ffea00",
      },
    });
  };
  const navigate = useNavigate();
  const onIdle = () => {
    localStorage.clear();
    navigate("/");
    notify();
  };
  useIdleTimer({
    onIdle,
    timeout: 300_000_000,
    throttle: 500,
  });

  return (
    <Dashboard>
      <Body>
        <BodyLeft>
          <Sidebar />
        </BodyLeft>
        <BodyRight>
          <Outlet />
          {children}
        </BodyRight>
      </Body>
    </Dashboard>
  );
};

export default Layout;
