import React, { useState, useEffect } from "react";
import "./App.css";
import AppRouter from "./routes/appRouter";
import { saveStaffInfo } from "../src/services/auth/authSlice";
import { store } from "./store/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../src/utils/logout";
import GeneralModal from "./components/Modal/InactivityModal";
import InactiveContent from "./components/InactiveContent";
import { Toaster } from "react-hot-toast";
import { useIdleTimer } from "react-idle-timer";
import { useLogoutMutation } from "./services/auth/authService";

function App() {
  const staffInfoJSON = localStorage.getItem("staffInfo");
  const staffInfo = staffInfoJSON ? JSON.parse(staffInfoJSON) : {};
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(60);
  const [logout] = useLogoutMutation();
  const currentTime = Date.now();
  const savedTokenTime = useSelector(
    (state: any) => state?.StaffDataReducer?.tokenExpiresIn
  );

  //if staff info, save it to store
  if (staffInfo) {
    store.dispatch(saveStaffInfo(staffInfo));
  }

  //Check for expired token
  useEffect(() => {
    if (staffInfo?.user) {
      if (currentTime > savedTokenTime) {
        handleLogout(logout, navigate);
      }
    }
  }, []);

  const handleCancel = () => {
    const givenTime = 300000;
    //set expire time in local storage
    const expireTime = Date.now() + givenTime;

    // set expire time in local storage
    localStorage.setItem("expireTime", JSON.stringify(expireTime));
    setShowModal(false);
  };

  const handleButtonClick = (handleLoggingOut: any) => {
    setShowModal(false);
    handleLoggingOut(logout, navigate);
  };

  const onIdle = () => {
    if (staffInfo?.user) {
      setShowModal(true);
    }
  };

  const sessionTime = process.env.REACT_APP_SESSION_TIME;

  useIdleTimer({
    onIdle,
    timeout: parseFloat(sessionTime as string) * 60 * 1000,
    throttle: 500,
  });

  useEffect(() => {
    if (showModal) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setCounter(60);
    }

    if (counter === 0) {
      setShowModal(false);
      handleLogout(logout, navigate);
    }
  }, [showModal, counter, navigate]);

  return (
    <>
      {showModal && (
        <GeneralModal general>
          <InactiveContent
            handleCancel={handleCancel}
            counter={counter}
            handleExitSession={() => handleButtonClick(handleLogout)}
            sessionTime={sessionTime as string}
          />
        </GeneralModal>
      )}
      <AppRouter />
      <Toaster
        position="top-right"
        toastOptions={{
          className: "",
          style: {
            margin: "10px",
            padding: "10px",
            display: "inline-flex",
            fontSize: "14px",
            zIndex: 999999,
          },
          duration: 4000,
          error: {
            style: {
              background: "red",
              color: "white",
            },
            iconTheme: {
              primary: "white",
              secondary: "red",
            },
          },
          success: {
            style: {
              background: "green",
              color: "white",
            },
            iconTheme: {
              primary: "white",
              secondary: "green",
            },
          },
        }}
      />
    </>
  );
}

export default App;
