import styled from "styled-components";

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;
export const Text = styled.p`
  font-size: 16px;
  padding: 2%;
`;
export const BodyText = styled.div`
  width: 100%;
  font-size: 20px;
  text-align: center;
  margin-top: -2rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
`;
export const TableInModal = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  background: white;
  margin-bottom: 3%;
  background: #fafbfb;
`;
export const IconDiv = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TitleText = styled.p`
  padding: 2rem;
  text-align: center;
`;
export const View = styled.div`
  width: 400px;
  padding: 3rem;
`;
export const PopupImage = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
`;
export const ModalWrapper = styled.div`
  background: #fff;
  border-radius: 15px;
  padding-left: 2%;
  padding-right: 2%;
  position: absolute !important;
  overflow-y: auto;
  z-index: 100;
`;
export const LoadWrapper = styled.div`
  background: #5c2684;
  border-radius: 15px;
  padding-left: 2%;
  padding-right: 2%;
  position: absolute !important;
  overflow-y: auto;
  z-index: 9999;
`;
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  //width:300px !important;
`;
export const Content = styled.div`
  padding-right: 1%;
  margin-top: -5%;
`;
export const Heading = styled.h3`
  letter-spacing: 1px;
  font-size: 20px;
`;
export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 1vw;
  margin-top: 20px;
`;
export const SubText = styled.p`
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
export const Table = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  background: white;
  margin-top: 1vw;
  background: #fafbfb;
`;
export const GridContainer = styled.div`
  display: flex;
`;
export const GridItems = styled.div`
  border: 1px solid #dfe2e6;
  padding: 10px;
  font-size: 11px;
  color: #2d2235;
  width: 20vw;
  display: flex;
  text-align: center;
`;
export const SubTex = styled.h4`
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
export const Input = styled.input`
  width: 100%;
  background: whitesmoke;
  font-size: 13px;
  font-weight: bold;
  padding-left: 10px;
  outline: none;
  border: none;
  height: 100px;
`;
export const ModalTexts = styled.h4`
  width: 300px;
  margin-top: 10px;
  text-align: center;
  padding: 15px;
  color: #2d2235;
  margin-top: 20px;
`;
export const CloseWrapper = styled.div`
  cursor: pointer;
`;
export const Top = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  // z-index: 3;
`;
