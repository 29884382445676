import {
  HiHome,
  HiOutlineUserGroup,
  HiOutlineGlobeAlt,
  HiTrendingUp,
  HiOutlineBadgeCheck,
  HiPencilAlt,
  HiCreditCard,
  HiArchive,
  HiCloudDownload,
  HiOutlineCalendar,
  HiClipboardList,
  HiCloudUpload,
  HiPrinter,
} from "react-icons/hi";
import { SidebarProps } from "../../types/types";

//sidebar data set
export const ClearingData: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Transactions",
    path: "/upload-naps",
    icon: HiArchive,
    subNav: [
      {
        title: "Upload NAPS",
        path: "/upload-naps",
        icon: HiCloudDownload,
      },
    ],
  },
  {
    title: "Reports",
    path: "/clearing-reports",
    icon: HiClipboardList,
  },
];

export const ClearingSupervisorData: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Approval",
    path: "/supervisor-queue",
    icon: HiTrendingUp,
  },
  {
    title: "Reports",
    path: "/clearing-reports",
    icon: HiClipboardList,
  },
];

export const SupervisorData: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Transactions",
    path: "/supervisor-queue",
    icon: HiHome,
  },

  {
    title: "Reports",
    path: "/all-reports",
    icon: HiClipboardList,
  },
];

export const AMUSupervisorSideButtons: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Standing Instructions",
    path: "/standing-instruction/view-si-reports",
    icon: HiArchive,
    subNav: [
      {
        title: "View",
        path: "/standing-instruction/view-si-reports",
        icon: HiCloudDownload,
      },
    ],
  },
  {
    title: "Reports",
    path: "/reports/view-si",
    icon: HiClipboardList,
  },
];

export const AMUSidebarButtons: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Standing Instructions",
    path: "/standing-instruction",
    icon: HiArchive,
    subNav: [
      {
        title: "Create",
        path: "/standing-instruction",
        icon: HiCloudDownload,
      },
      {
        title: "View",
        path: "/standing-instruction/view",
        icon: HiCloudDownload,
      },
      {
        title: "Stop",
        path: "/standing-instruction/stop",
        icon: HiCloudDownload,
      },
    ],
  },
  {
    title: "Reports",
    path: "/view-si-reports",
    icon: HiClipboardList,
  },
];
export const TellerSidebarData: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Transactions",
    path: "/transaction",
    icon: HiCloudUpload,
    subNav: [
      {
        title: "Create",
        path: "/transaction/upload-naps",
        icon: HiCloudDownload,
      },
      {
        title: "Bulk",
        path: "/transaction/pending-bulk",
        icon: HiCloudDownload,
      },
    ],
  },
  {
    title: "Reports",
    path: "/all-reports",
    icon: HiClipboardList,
  },
];

export const AdminSideBarButtons: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Profile Management",
    path: "/profile-management",
    icon: HiOutlineUserGroup,
  },
  {
    title: "Charge Update",
    path: "/charge-update",
    icon: HiPencilAlt,
  },
  {
    title: "Approval",
    path: "/approval",
    icon: HiCloudUpload,
    subNav: [
      {
        title: "User Approval",
        path: "/approval/user-approval",
        icon: HiCloudUpload,
      },
      {
        title: "Charge Approval",
        path: "/approval/charge-approval",
        icon: HiCloudUpload,
      },
    ],
  },
];

export const SuperUserSideBarButtons: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Profile Management",
    path: "/profile-management",
    icon: HiOutlineUserGroup,
  },
];
