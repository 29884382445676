import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import { generateHeader } from "../../utils/generateHeaders";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const END_POINT = `/charges`;

interface ChargeResponse {
  id: number;
  chargeAmount: number;
  minimumTransactionAmount: number;
  maximumTransactionAmount: number;
  fcmbIncome: number;
  nibssIncome: number;
  vat: number;
  createdOn: string;
  lastModified: Date;
  createdBy: string;
}

interface AddResponse {
  data: string;
  error: string;
  responseCode: string;
  responseDescription: string;
}
interface UpdateChargeData {
  data: ChargeResponse;
  responseCode: string;
  responseDescription: string;
  error: string;
}

export interface ChargeStruct {
  [key: string]: number;
  chargeAmount: number;
  minimumTransactionAmount: number;
  maximumTransactionAmount: number;
  fcmbIncome: number;
  nibssIncome: number;
  vat: number;
}

interface ChargeStructRequest {
  body?: ChargeStruct;
  chargeId?: number;
}

interface ChargeStructResponse {
  data?: ChargeStruct;
}

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,

    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;

      headers.set("Content-Type", "application/json");
      headers.set("status", "true");
      headers.set("accept", "text/plain");
      headers.set("Authorization", `Bearer ${token}`);

      const generatedHeaders = generateHeader();
      headers.set("X-Frame-Options", "DENY");
      headers.set("X-Content-Type-Options", "nosniff");
      headers.set("Cross-Origin-Opener-Policy", "same-origin");
      headers.set(
        "Content-Security-Policy",
        "frame-ancestors 'self' X-Frame-Options: DENY"
      );

      headers.set("x-token", generatedHeaders["x-token"]);
      headers.set(
        "Ocp-Apim-Subscription-Key",
        generatedHeaders["Ocp-Apim-Subscription-Key"] as string
      );
      headers.set("Ocp-Apim-Trace", "true");
      headers.set("UTCTimestamp", generatedHeaders.UTCTimestamp);
      headers.set("Client_ID", generatedHeaders["Client_ID"]);
    },
  })(args, api, extraOptions);

  const newResponse: any = {
    ...baseResult,
  };
  const errorCode = newResponse?.data?.statusCode;
  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const chargeApi = createApi({
  reducerPath: "amuApi",
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    //get all charges
    getAllCharges: builder.query<ChargeResponse[], void>({
      query: () => `${END_POINT}`,
      transformResponse: (response: { data: ChargeResponse[] }, meta, arg) =>
        response.data,
    }),

    //get a charge
    getCharge: builder.mutation<ChargeStructResponse, { id: number }>({
      query: ({ id }) => ({
        url: `${END_POINT}/${id}`,
        method: "GET",
      }),
    }),
    //add a charge
    addCharge: builder.mutation<AddResponse, Partial<ChargeStruct>>({
      query: (body) => ({
        url: `${END_POINT}`,
        method: "POST",
        body,
      }),
    }),

    //update a charge
    updateCharge: builder.mutation<
      UpdateChargeData,
      Partial<ChargeStructRequest>
    >({
      query: ({ body, chargeId }) => ({
        url: `${END_POINT}/updatecharge`,

        method: "POST",
        body,
        headers: {
          chargeId: `${chargeId}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllChargesQuery,
  useGetChargeMutation,
  useAddChargeMutation,
  useUpdateChargeMutation,
} = chargeApi;
