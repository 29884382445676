import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorFormatter } from "../../../utils/errorFormatter";
import { useAppSelector } from "../../hooks";
import toast from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const END_POINT = `transactions/batch`;

export const retrieveBatch = createAsyncThunk<any, any>(
  "data/getData",
  async (payload, { rejectWithValue }) => {
    try {
      const localInfo = localStorage.getItem("staffInfo");
      const tokenValue = JSON.parse(localInfo ?? "{}")?.accessToken;

      const headers = {
        Authorization: `Bearer ${tokenValue}`,
      };

      const response = await axios.get(
        `${BASE_URL}${END_POINT}/${payload?.batchId}?PageSize=${payload?.pageSize}`,
        {
          headers,
        }
      );
      if (response?.data?.statusCode === 401) {
        localStorage.clear();
        window.location.href = "/login";
      }
      //toast.success(response.data?.responseDescription);
      return response.data;
    } catch (err: any) {
      toast.error(err?.response?.data?.responseDescription);
      return rejectWithValue(err);
    }
  }
);

// Create a slice to store the response data from posting data
const batchGet = createSlice({
  name: "batch",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveBatch.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.data = null;
      })
      .addCase(retrieveBatch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(retrieveBatch.rejected, (state: any, action) => {
        const message = errorFormatter(action.payload);
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default batchGet.reducer;
