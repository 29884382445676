import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import { defaultHeaders, generateHeader } from "../../utils/generateHeaders";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// interface LoginResponse {
//   message: string;
//   responseDescription: string;
//   data: {
//     accessToken: string;
//     expiresIn: number;
//     tokenType: string;
//     user: {
//       managerDepartment: string;
//       managerName: string;
//       staffName: string;
//       displayName: string;
//       email: string;
//       department: string;
//       groups: string;
//       mobileNo: string;
//       staffId: string;
//       roleName: string;
//       response: string;
//       responseMessage: string;
//     };
//   };
// }

export interface LoginResponse {
  responseCode?: string;
  responseDescription?: string;
  data?: LoginData;
  error?: string;
}

export interface LoginData {
  accessToken?: string;
  expiresIn?: number;
  tokenType?: string;
  user?: User;
}

export interface User {
  id?: number;
  username?: string;
  roleId?: number;
  isActive?: boolean;
  email?: string;
  displayName?: string;
  department?: string;
  groups?: string;
  mobileNo?: string;
  staffID?: string;
  branchID?: string;
  sol?: string;
  managerDepartment?: string;
  managerName?: string;
  lastLogin?: Date;
  roleName?: string;
  isLoggedIn?: boolean;
  isTokenValid?: boolean;
  isAccountLocked?: boolean;
  loginFailureCount?: number;
  createdOn?: Date;
  lastModified?: Date;
  createdBy?: string;
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,

    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;
      const generatedHeaders = generateHeader();

      if (token) {
        headers.set("Content-Type", "application/json");
        headers.set("status", "true");
        headers.set("accept", "text/plain");
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("X-Frame-Options", "DENY");
      headers.set("X-Content-Type-Options", "nosniff");
      headers.set("Cross-Origin-Opener-Policy", "same-origin");
      headers.set(
        "Content-Security-Policy",
        "frame-ancestors 'self' X-Frame-Options: DENY"
      );

      headers.set("x-token", generatedHeaders["x-token"]);
      headers.set(
        "Ocp-Apim-Subscription-Key",
        generatedHeaders["Ocp-Apim-Subscription-Key"] as string
      );
      headers.set("Ocp-Apim-Trace", "true");
      headers.set("UTCTimestamp", generatedHeaders.UTCTimestamp);
      headers.set("Client_ID", generatedHeaders["Client_ID"]);
    },
  }),

  endpoints: (builder) => ({
    //login
    login: builder.mutation<
      LoginResponse,
      { username: string; password: string; token: string }
    >({
      query: (body) => ({
        url: "/security",
        method: "POST",
        headers: {
          password: body.password, // Set the password in the header
        },
        body: { username: body.username, token: body.token },
      }),
    }),

    logout: builder.mutation<any, any>({
      query: () => ({
        url: "/security/logout",
        method: "POST",
      }),
    }),

    // get reset token
    getToken: builder.mutation<any, { username: string }>({
      query: ({ username }) => ({
        url: `/security/generate-reset-token?username=${username}`,
        method: "GET",
        headers: { ...defaultHeaders },
      }),
    }),

    //login
    resetUser: builder.mutation<any, { username: string; token: string }>({
      query: (body) => ({
        url: "/security/reset-user",
        method: "POST",

        body,
      }),
    }),

    //logout all
    logoutAll: builder.mutation<any, { username: string }>({
      query: ({ username }) => ({
        url: `/security/logout-all?username=${username}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useGetTokenMutation,
  useResetUserMutation,
  useLogoutAllMutation,
} = authApi;
