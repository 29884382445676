import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";

const localInfo = localStorage.getItem("staffInfo");
const tokenValue = JSON.parse(localInfo ?? "{}")?.accessToken;

const headers = {
  Authorization: `Bearer ${tokenValue}`,
};

const BASE_URL = process.env.REACT_APP_BASE_URL;
const END_POINT = `transactions`;

export const postData = createAsyncThunk<
  any,
  { payload: any; accessToken: string | undefined }
>("data/postData", async ({ payload, accessToken }, { rejectWithValue }) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await axios.post(`${BASE_URL}${END_POINT}`, payload, {
      headers,
    });
    toast.success(response.data?.responseDescription);

    if (response?.data?.statusCode === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }
    return response.data;
  } catch (error: any) {
    toast.error(error?.response.data?.responseDescription);
    return rejectWithValue(error);
  }
});

type InitialStateType = {
  isLoading: boolean;
  error: any;
  data: null | any[];
};

const initialState: InitialStateType = {
  isLoading: false,
  error: null,
  data: null,
};

// Create a slice to store the response data from posting data
const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    clearData: (state) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.data = null;
      })
      .addCase(postData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.data = action.payload?.data;
      })
      .addCase(postData.rejected, (state, action: any) => {
        state.isLoading = false;
        state.data = null;
        state.error = action.payload;
      });
  },
});
export const { clearData } = postSlice.actions;
export default postSlice.reducer;
