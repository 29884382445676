import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import { generateHeader } from "../../utils/generateHeaders";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const localInfo = localStorage.getItem("staffInfo");
const tokenValue = JSON.parse(localInfo ?? "{}")?.accessToken;
interface FrequencyResponse {
  id: number;
  name: string;
}

interface BankList {
  id: string;
  name: string;
  code: string;
}
interface BankListResponse {
  value: {
    responseCode: string;
    responseDescription: string;
    data: BankList[];
  };
}

interface TypeList {
  id: number;
  name: string;
}
export interface SiList {
  id?: number;
  requestId?: string;
  customerId?: string;
  debitAccount?: string;
  debitAccountName?: string;
  debitBankVerificationNumber?: string;
  debitKYCLevel?: string;
  beneficiaryAccount?: string;
  beneficiaryAccountName?: string;
  beneficiaryBank?: string;
  beneficiaryInstitutionCode?: string;
  amount?: number;
  creditNarration?: string;
  debitNarration?: string;
  initiatingBranchCode?: string;
  branchCode?: string;
  onHoliday?: string;
  instructionType?: number;
  frequency?: number;
  startDate?: string;
  endDate?: string;
  approvalStatus?: string;
  initiatedBy?: string;
  approvedBy?: string;
  declinedBy?: string;
  failureReason?: string;
  isValid?: boolean;
  isSingleDebit?: boolean;
  processingStatus?: string;
  valueDate?: Date;
  chargeFees?: number;
  channelCode?: string;
  isDeleted?: boolean;
  deletedBy?: string;
  deleteApprovedBy?: string;
  deleteApprovalStatus?: string;
  deleteReason?: string;
  createdOn?: Date;
  lastModified?: Date;
  createdBy?: string;
}
export interface SIRes {
  responseCode?: string;
  responseDescription?: string;
  status?: boolean;
  data?: SiList[];
}
export interface DebitType {
  responseCode?: string;
  responseDescription?: string;
  status?: boolean;
  data: string;
}
export interface StandingPayload {
  debitAccount?: string;
  debitAccountName?: string;
  beneficiaryAccount?: string;
  beneficiaryAccountName?: string;
  beneficiaryBank?: string;
  beneficiaryInstitutionCode?: string;
  amount?: number;
  creditNarration?: string;
  debitNarration?: string;
  onHoliday?: string;
  instructionType?: number;
  frequency?: number;
  channelCode?: string;
  startDate?: string;
  endDate?: string;
}

interface UpdateStanding {
  responseCode: string;
  responseDescription: string;
  status: boolean;
  data: string;
}

interface ApproveInstruction {
  tranId: string;
  isApprove: boolean;
  reason: string;
}
interface StandingRes {
  responseDescription: string;
  data: string;
  status: boolean;
  responseCode: string;
}

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,

    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;

      headers.set("Content-Type", "application/json");
      headers.set("status", "true");
      headers.set("accept", "text/plain");
      headers.set("Authorization", `Bearer ${token}`);

      const generatedHeaders = generateHeader();
      headers.set("X-Frame-Options", "DENY");
      headers.set("X-Content-Type-Options", "nosniff");
      headers.set("Cross-Origin-Opener-Policy", "same-origin");
      headers.set(
        "Content-Security-Policy",
        "frame-ancestors 'self' X-Frame-Options: DENY"
      );

      headers.set("x-token", generatedHeaders["x-token"]);
      headers.set(
        "Ocp-Apim-Subscription-Key",
        generatedHeaders["Ocp-Apim-Subscription-Key"] as string
      );
      headers.set("Ocp-Apim-Trace", "true");
      headers.set("UTCTimestamp", generatedHeaders.UTCTimestamp);
      headers.set("Client_ID", generatedHeaders["Client_ID"]);
    },
  })(args, api, extraOptions);

  const newResponse: any = {
    ...baseResult,
  };
  const errorCode = newResponse?.data?.statusCode;
  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const standingApi = createApi({
  reducerPath: "standingApi",
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    //get all charges
    getAllFrequencies: builder.query<FrequencyResponse[], void>({
      query: (token) => ({
        url: "standinginstructions/frequency",
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
      }),

      transformResponse: (response: { data: FrequencyResponse[] }, meta, arg) =>
        response.data,
    }),

    //get all outward
    getAllBanks: builder.query<BankListResponse, void>({
      query: (token) => ({
        url: "/info/bank-list",
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
      }),
    }),

    //get all outward
    getAllTypes: builder.query<TypeList[], void>({
      query: (token) => ({
        url: "/standinginstructions/type",
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
      }),
      transformResponse: (response: { data: TypeList[] }, meta, arg) =>
        response.data,
    }),

    createStanding: builder.mutation<StandingRes, StandingPayload>({
      query: (body) => ({
        url: "/standinginstructions",
        method: "POST",
        body,
      }),
    }),

    //get all standing instructions
    getAllSI: builder.query<SIRes, void>({
      query: (token) => ({
        url: "/standinginstructions",
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
      }),
    }),

    //get a standing instructions
    getSI: builder.mutation<SIRes, { status?: string; token: string }>({
      query: ({ token, status }) => ({
        url: `/standinginstructions/${status}`,
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
        method: "GET",
      }),
    }),

    getSIInitiated: builder.query<any, { startDate: string; endDate: string }>({
      query: ({ startDate, endDate }) => ({
        url: `/standinginstructions/initiated?StartDate=${startDate}&EndDate=${endDate}`,
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
        method: "GET",
      }),
    }),

    getSIList: builder.query<SIRes, { status?: string; token: string }>({
      query: ({ token, status }) => ({
        url: `/standinginstructions/${status}`,
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
        method: "GET",
      }),
    }),

    getDebitAccount: builder.query<any, string>({
      query: (account) => ({
        url: `/transactions/verify/debit-account/${account}`,
        method: "GET",
      }),
      transformResponse: (response: { data: DebitType }, meta, arg) =>
        response.data,
    }),

    postCreditAccount: builder.mutation<
      {
        responseCode: string;
        responseDescription: string;
        data: string;
        error: string;
      },
      { destinationInstitutionCode: string; accountNumber: string }
    >({
      query: (body) => ({
        url: "/transactions/verify/credit-account",
        method: "POST",
        body,
      }),
    }),
    getInstructionsByAccNumber: builder.mutation<any, { accNo: string }>({
      query: ({ accNo }) => ({
        url: `/standinginstructions/instruction-by-account?accountNumber=${accNo}`,
        method: "GET",
      }),
    }),
    approveInstruction: builder.mutation<
      UpdateStanding,
      Partial<ApproveInstruction>
    >({
      query: (body) => ({
        url: `/standinginstructions/approvedeclineinstruction`,
        method: "PUT",
        body,
      }),
    }),

    deleteInstruction: builder.mutation<any, { requestId: string }>({
      query: ({ requestId }) => ({
        url: `/standinginstructions/stop-instruction/${requestId}`,
        method: "PUT",
      }),
    }),

    getBulkPending: builder.mutation<any, void>({
      query: () => ({
        url: `/transactions/bulk-pending`,
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
        method: "GET",
      }),
    }),

    initiateTransaction: builder.mutation<
      any,
      { batchId: string; transactionIds: string[] }
    >({
      query: (body) => ({
        url: `/transactions/initiate`,
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
        method: "POST",
        body,
      }),
    }),

    //get pending user
    pendingUserApproval: builder.mutation<any, void>({
      query: (token) => ({
        url: "admin/users-pending-approval",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    //approve user
    approveUser: builder.mutation<
      any,
      { username: string; isApproved: boolean }
    >({
      query: (body) => ({
        url: "admin/approval-user",
        method: "POST",
        body,
      }),
    }),

    //get pending charge
    pendingChargeApproval: builder.mutation<any, void>({
      query: (token) => ({
        url: "charges/charge-pending-approval",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    //approve charge
    approveCharge: builder.mutation<any, { id: number; isApproved: boolean }>({
      query: (body) => ({
        url: "charges/approval-charge",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllFrequenciesQuery,
  useGetAllBanksQuery,
  usePostCreditAccountMutation,
  useGetDebitAccountQuery,
  useGetAllTypesQuery,
  useApproveInstructionMutation,
  useCreateStandingMutation,
  useGetInstructionsByAccNumberMutation,
  useGetAllSIQuery,
  useGetSIListQuery,
  useGetSIInitiatedQuery,
  useDeleteInstructionMutation,
  useGetSIMutation,
  useGetBulkPendingMutation,
  useInitiateTransactionMutation,
  usePendingUserApprovalMutation,
  useApproveUserMutation,
  usePendingChargeApprovalMutation,
  useApproveChargeMutation,
} = standingApi;
