import { createSlice } from "@reduxjs/toolkit";
import { LoginData } from "./authService";

// This slice holds user needed information
interface LoginState {
  staffInfo: LoginData | undefined;
  openUserDrop: boolean;
  password: string;
  tokenExpiresIn: number;
}

const initialState: LoginState = {
  staffInfo: undefined,
  openUserDrop: false,
  password: "",
  tokenExpiresIn: 0,
};

const StaffData = createSlice({
  name: "User data",
  initialState,
  reducers: {
    saveStaffInfo: (state, action) => {
      state.staffInfo = action.payload;
    },
    setOpenUserDrop: (state, action) => {
      state.openUserDrop = action.payload;
    },
    savePassword: (state, action) => {
      state.password = action.payload;
    },
    saveTokenExpiresIn: (state, action) => {
      state.tokenExpiresIn = action.payload;
    },
  },
});
export const StaffDataReducer = StaffData.reducer;

export const {
  saveStaffInfo,
  setOpenUserDrop,
  savePassword,
  saveTokenExpiresIn,
} = StaffData.actions;
