import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { authApi } from "../services/auth/authService";
import { rolesApi } from "../services/user-management/assignRoles";
import { chartApi } from "../services/activities/chartService";
import { transactionsApi } from "../services/activities/transactionStats";
import { chargeApi } from "../services/charges/chargeService";
import { postStandingInstruction } from "../services/standingInstructions/createService";
import { standingApi } from "../services/standingInstructions/standingService";
import { reportsApi } from "../services/reports/reportService";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

//const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store using the persisted reducer
// Create a persisted store

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat([
      authApi.middleware,
      chartApi.middleware,
      rolesApi.middleware,
      chargeApi.middleware,
      transactionsApi.middleware,
      postStandingInstruction.middleware,
      standingApi.middleware,
      reportsApi.middleware,
    ]),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
