import toast from "react-hot-toast";
import { authApi } from "../services/auth/authService";
import { rolesApi } from "../services/user-management/assignRoles";
import { persistor, store } from "../store/store";
import { useEffect } from "react";

export const handleLogout = async (logout: any, navigate: any) => {
  try {
    const response = await logout().unwrap();
    if (response?.responseCode === "00") {
      localStorage.clear();
      persistor.purge();
      store.dispatch(authApi.util.resetApiState());
      store.dispatch(rolesApi.util.resetApiState());
      toast.success(response?.responseDescription);
      navigate("/login");
    } else if (response?.statusCode === 401) {
      console.log(response);
      toast.error(response?.value?.responseDescription);
    }
  } catch (err: any) {
    console.log(err);
    toast.error(err?.value?.responseDescription);
  }
};
