import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorFormatter } from "../../../utils/errorFormatter";

const localInfo = localStorage.getItem("staffInfo");
const tokenValue = JSON.parse(localInfo ?? "{}")?.accessToken;

const headers = {
  Authorization: `Bearer ${tokenValue}`,
};

const BASE_URL = process.env.REACT_APP_BASE_URL;
const END_POINT = `transactions/uploadedsummary`;

export const getSummary = createAsyncThunk<any>("data/getUploads", async () => {
  const response = await axios.get(`${BASE_URL}${END_POINT}`, { headers });

  return response?.data;
});

// slice for getting uploaded data from endpoint

const fetchSummary = createSlice({
  name: "summary",
  initialState: {
    data: null,
    loading: false,
    error: "no error",
  },
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(getSummary.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSummary.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getSummary.rejected, (state, action) => {
        const message = errorFormatter(action.payload);
        state.error = message;
        state.data = null;
        state.loading = false;
      });
  },
});

export default fetchSummary.reducer;
