import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import { generateHeader } from "../../utils/generateHeaders";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const localInfo = localStorage.getItem("staffInfo");
const tokenValue = JSON.parse(localInfo ?? "{}")?.accessToken;

interface PostData {
  username: string;
  role: string;
  token: string;
}

interface AssignStruct {
  id: number;
  username: string;
  role: string;
  isActive: boolean;
  lastLogin: string;
  createdOn: string;
  lastModified: string;
}
interface AssignResponseData {
  statusCode: string;
  responseDescription: string;
  data: null;
}

interface ActionResponse {
  description: string;
  statusCode: number;
  message: string;
  data: boolean;
}
interface RoleResponseData {
  id: string;
  roleName: string;
  isActive: boolean;
}
interface RoleResponse {
  responseDescription: any;
  data: [RoleResponseData];
}

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,

    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;

      headers.set("Content-Type", "application/json");
      headers.set("status", "true");
      headers.set("accept", "text/plain");
      headers.set("Authorization", `Bearer ${token}`);

      const generatedHeaders = generateHeader();
      headers.set("X-Frame-Options", "DENY");
      headers.set("X-Content-Type-Options", "nosniff");
      headers.set("Cross-Origin-Opener-Policy", "same-origin");
      headers.set(
        "Content-Security-Policy",
        "frame-ancestors 'self' X-Frame-Options: DENY"
      );

      headers.set("x-token", generatedHeaders["x-token"]);
      headers.set(
        "Ocp-Apim-Subscription-Key",
        generatedHeaders["Ocp-Apim-Subscription-Key"] as string
      );
      headers.set("Ocp-Apim-Trace", "true");
      headers.set("UTCTimestamp", generatedHeaders.UTCTimestamp);
      headers.set("Client_ID", generatedHeaders["Client_ID"]);
    },
  })(args, api, extraOptions);

  const newResponse: any = {
    ...baseResult,
  };
  const errorCode = newResponse?.data?.statusCode;
  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const rolesApi = createApi({
  reducerPath: "adminApi",
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    //activate user
    activateUser: builder.mutation<
      ActionResponse,
      { username: string; token: string }
    >({
      query: (body) => ({
        url: `admin/users/${body.username}/activate`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
    }),

    //deactivate user
    deactivateUser: builder.mutation<
      ActionResponse,
      { username: string; token: string }
    >({
      query: (body) => ({
        url: `admin/users/${body.username}/deactivate`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
    }),
    // get roles
    getRoles: builder.query<RoleResponseData[], void>({
      query: () => ({
        url: "admin/role",
      }),
      transformResponse: (response: { data: RoleResponseData[] }, meta, arg) =>
        response.data,
    }),
    //get users
    getAllUsers: builder.query<AssignStruct[], void>({
      query: () => ({
        url: "admin/users",
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
      }),
      transformResponse: (response: { data: AssignStruct[] }, meta, arg) =>
        response.data,
    }),
    getUser: builder.mutation<any[], { userName: string }>({
      query: ({ userName }) => ({
        url: `admin/user/${userName}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
      }),
    }),
    //assign role
    assignRole: builder.mutation<AssignResponseData, Partial<PostData>>({
      query: (body) => ({
        url: "admin/userrole",
        method: "POST",
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
        body,
      }),
    }),
  }),
});

export const {
  useActivateUserMutation,
  useDeactivateUserMutation,
  useGetRolesQuery,
  useGetUserMutation,
  useGetAllUsersQuery,
  useAssignRoleMutation,
} = rolesApi;
