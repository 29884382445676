import { Suspense, lazy, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { CustomHashLoader } from "../components/LazyLoader";
import Layout from "../layout/Layout";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import Protected from "./ProtectedRoutes";
import {
  resetPersistedTable,
  resetSlice,
  setPersistedTable,
} from "../store/slices/reportTableSlice";
import PendingBulk from "../pages/dashboard/bulk-pending";
import UserApproval from "../pages/dashboard/userApproval";
import ChargeApproval from "../pages/dashboard/chargeApproval";

const Login = lazy(() => import("../pages/login"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const Upload = lazy(() => import("../pages/dashboard/upload-naps/Upload"));
const UploadSI = lazy(() => import("../pages/dashboard/upload-naps/Upload"));
const Transactions = lazy(() => import("../pages/dashboard/transactions"));
const ClearingReports = lazy(
  () => import("../pages/dashboard/clearing-reports")
);
const Create = lazy(() => import("../pages/dashboard/create"));
const View = lazy(() => import("../pages/dashboard/view-si"));
const ViewSIReports = lazy(() => import("../pages/dashboard/view-si"));
const Reconciliation = lazy(
  () => import("../pages/dashboard/reconciliation-reports")
);
const ProfileManagement = lazy(
  () => import("../pages/dashboard/profile-management")
);
const Charges = lazy(() => import("../pages/dashboard/charge-update"));
const Approved = lazy(() => import("../pages/dashboard/Approved"));
const ViewTransactions = lazy(
  () => import("../pages/dashboard/view-transactions")
);
const ViewBatch = lazy(() => import("../pages/dashboard/Batch"));
const StopSi = lazy(() => import("../pages/dashboard/stop-si"));
const ViewSi = lazy(() => import("../pages/dashboard/viewSI"));
const AMUReports = lazy(() => import("../pages/dashboard/amu-reports"));
const AllReport = lazy(() => import("../pages/dashboard/all-reports"));
const AppRouter = () => {
  const localInfo = useAppSelector(
    (state: any) => state.StaffDataReducer?.staffInfo
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentURL = location.pathname;
  useEffect(() => {
    if (!currentURL.toLowerCase().includes("all-reports")) {
      dispatch(resetSlice());
      dispatch(resetPersistedTable());
    }
    return () => {
      dispatch(resetPersistedTable());
    };
  }, [currentURL]);

  return (
    <Suspense fallback={<CustomHashLoader color="#5c2684" size={60} />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <Protected isVerified={localInfo}>
              {" "}
              <Layout children={<></>} title={""} />{" "}
            </Protected>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="home" element={<Dashboard />} />
          <Route path="upload-naps" element={<Upload />} />

          <Route path="supervisor-queue" element={""}>
            <Route path="" element={<Transactions />} />
            <Route path="view-batch/:batchId" element={<ViewBatch />} />
          </Route>
          <Route path="supervisors/view-batch" element={<Transactions />} />
          <Route path="all-reports" element="">
            <Route path="" element={<AllReport currentURL={currentURL} />} />

            <Route
              path="view-transactions/:batchId"
              element={<ViewTransactions />}
            />
          </Route>
          {/* <Route
            path="all-reports"
            element={<AllReport currentURL={currentURL} />}
          /> */}
          <Route path="standing-instruction" element={""}>
            <Route path="" element={<Create />} />
            <Route path="view" element={<AMUReports />} />
            <Route path="stop" element={<StopSi />} />
            <Route path="view-si-reports" element={<ViewSi />} />
          </Route>
          <Route path="reports/view-si" element={<View />} />
          <Route path="upload-si" element={<UploadSI />} />
          <Route
            path="clearing-reports"
            element={<ClearingReports currentURL={currentURL} />}
          />
          <Route path="view-si-reports" element={<ViewSIReports />} />
          <Route path="view-standinginstruction" element={<AMUReports />} />
          <Route path="reconciliation-reports" element={<Reconciliation />} />
          <Route path="profile-management" element={<ProfileManagement />} />
          <Route path="user-approval" element={<ProfileManagement />} />
          <Route path="charge-update" element={<Charges />} />
          <Route path="view-instructions" element={<ViewSi />} />

          <Route path="approved" element={<Approved />} />

          <Route path="transaction" element={""}>
            <Route index element={<Upload />} />
            <Route path="pending-bulk" element={<PendingBulk />} />
            <Route path="upload-naps" element={<Upload />} />
            <Route path="view-batch/:batchId" element={<ViewBatch />} />
          </Route>

          <Route path="approval" element={""}>
            <Route index element={<UserApproval />} />
            <Route path="user-approval" element={<UserApproval />} />
            <Route path="charge-approval" element={<ChargeApproval />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
