import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { errorFormatter } from "../../../utils/errorFormatter";
import { useAppSelector } from "../../hooks";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const ENDPOINT = `transactions/approve`;

export const approveTransaction = createAsyncThunk<any, any>(
  "data/approveData",
  async (payload, { rejectWithValue }) => {
    try {
      const localInfo = localStorage.getItem("staffInfo");
      const tokenValue = JSON.parse(localInfo ?? "{}")?.accessToken;

      const headers = {
        Authorization: `Bearer ${tokenValue}`,
      };

      const response: any = await axios.post(
        `${BASE_URL}${ENDPOINT}`,
        payload,
        { headers }
      );

      if (response?.data?.responseCode === "99") {
        toast.error(response?.data.data);
      } else if (response?.data?.responseDescription?.length === 0) {
        toast.success(response?.data.data);
      } else if (response?.data?.statusCode === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        toast.success(response?.data?.responseDescription);
      }
      return response.data;
    } catch (err: any) {
      if (err?.responseDescription === null || undefined) {
        toast.error(err.errors.TransactionIds[0]);
      } else {
        toast.error(err?.response?.data.responseDescription);
      }

      return rejectWithValue(err);
    }
  }
);

const approveTransactionDetails = createSlice({
  name: "initiate",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(approveTransaction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.data = null;
      })
      .addCase(approveTransaction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(approveTransaction.rejected, (state: any, action) => {
        const message = errorFormatter(action.payload);
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default approveTransactionDetails.reducer;
