import styled from "styled-components";
import { Wrapper, InputProps } from "../../types/types";

export const DWrap = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 10px;
`;
export const PaginatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const InputContainer = styled.div<Wrapper>`
  display: flex;
  gap: 4px;
  background: ${(props) => (props.background ? props.background : "#fafafa")};
  align-items: center;
  border-radius: 9px;
  width: 92%;
  height: 3.5vw;
  margin-top: -1vw;
  border: ${(props) => (props.border ? props.border : "1px solid #dfe2e6")};
  padding-inline: 24px;
`;

export const CheckWrap = styled.div`
  width: 95%;
  display: flex;
  gap: 5px;
  flex-flow: column;
`;

export const Input = styled.input<InputProps>`
  border-radius: 3px;
  width: 85%;
  font-size: 16px;
  border: none;
  font-family: "Poppins", sans-serif;
  padding: ${(props) => (props.padding ? props.padding : "8px 18px")};
  opacity: ${(props) => props.opacity};
  background: none;
  color: black;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::placeholder {
    font-size: 12px;
  }

  &:focus {
    // outline: 1px solid #784b99;
  }
`;

export const Show = styled.p`
  cursor: pointer;
  color: #4e5152;
  font-size: 14px;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

export const ErrorText = styled.p`
  font-size: 12px;
  color: red;
  padding-top: 3px;
  height: 5px;
  margin-top: -0.4vw;
`;

export const Label = styled.p`
  font-weight: 400;
  font-size: 14px;
`;
export const Iconwrapper = styled.div`
  margin-right: 20px;
  @media screen and (max-width: 600px) {
    margin-right: 18px;
  }
`;

export const DropDownWrapper = styled.div`
  position: relative;
  padding: 8px 4px;
  background: #fafafa;
  /* height: 48px; */
  border-radius: 8px;
  border: 1px solid #dfe2e6;
`;
export const DropInner = styled.div`
  display: flex;
  margin: 0 !important;
  padding: 8px 5px;
  background: #fafafa;
  align-items: center;
`;

export const ShowList = styled.div`
  background: #fafafa;
  width: 90%;
  align-items: center;
  display: flex;
  gap: 10px;
  margin-top: 4px;
  padding-inline: 24px;
`;

export const ShowListIcon = styled.div`
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  cursor: pointer;
`;

export const DropDown = styled.div`
  background: #fafafa;
  border: 1px solid #dfe2e6;
  position: absolute;
  width: 100%;
  top: 50px;
  overflow-y: auto;
  padding: 0px !important;
  margin: 0px !important;
  right: 1px;
  z-index: 5;
  border-radius: 0 0 8px 8px;
`;

export const ListItems = styled.ul`
  display: flex;
  height: 100%;
  margin: 0px !important;
  flex-direction: column;
  padding: 0px !important;
`;

export const ListItem = styled.li`
  display: flex;
  padding: 20px 24px;
  align-items: center;
  font-size: 14px;
  border: 1px solid #dfe2e6;

  &:hover {
    background-color: #5c2684;
    cursor: pointer;
    color: white;
  }
`;

export const Item = styled.p`
  font-size: 13px;
  color: #98a1b0;
  font-family: "Poppins", sans-serif;
`;
export const Items = styled.p`
  font-size: 16px;
  color: black;
  font-family: "Poppins", sans-serif;
`;
export const DefaultItem = styled.p`
  font-size: 14px;
  color: #727474;
`;
export const OtherInput = styled.input`
  height: 80%;
  width: 80%;
  border: none;
  font-size: 14px;
  outline: none;
`;

export const InvisibleBackDrop = styled.div`
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
`;

export const ErrMsg = styled.div`
  color: red;
  font-size: 12px;
  justify-content: center;
  align-items: center;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
