import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import { generateHeader } from "../../utils/generateHeaders";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const localInfo = localStorage.getItem("staffInfo");
const tokenValue = JSON.parse(localInfo ?? "{}")?.accessToken;

interface PostSI {
  debitAccount: string;
  debitAccountName: string;
  beneficiaryAccount: string;
  beneficiaryAccountName: string;
  beneficiaryBank: string;
  beneficiaryInstitutionCode: string;
  amount: number;
  creditNarration: string;
  debitNarration: string;
  onHoliday: string;
  frequency: number;
  channelCode: string;
  startDate: string;
  endDate: string;
}

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,

    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;

      headers.set("Content-Type", "application/json");
      headers.set("status", "true");
      headers.set("accept", "text/plain");
      headers.set("Authorization", `Bearer ${token}`);

      const generatedHeaders = generateHeader();
      headers.set("X-Frame-Options", "DENY");
      headers.set("X-Content-Type-Options", "nosniff");
      headers.set("Cross-Origin-Opener-Policy", "same-origin");
      headers.set(
        "Content-Security-Policy",
        "frame-ancestors 'self' X-Frame-Options: DENY"
      );

      headers.set("x-token", generatedHeaders["x-token"]);
      headers.set(
        "Ocp-Apim-Subscription-Key",
        generatedHeaders["Ocp-Apim-Subscription-Key"] as string
      );
      headers.set("Ocp-Apim-Trace", "true");
      headers.set("UTCTimestamp", generatedHeaders.UTCTimestamp);
      headers.set("Client_ID", generatedHeaders["Client_ID"]);
    },
  })(args, api, extraOptions);

  const newResponse: any = {
    ...baseResult,
  };
  const errorCode = newResponse?.data?.statusCode;
  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const postStandingInstruction = createApi({
  reducerPath: "postStandingInstruction",
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    postSIdata: builder.mutation<PostSI, void>({
      query: (data) => ({
        url: "/standinginstructions",
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { usePostSIdataMutation } = postStandingInstruction;
