import React, { useState } from "react";
import {
  ButtonWrapper,
  LeftIconContainer,
  IconContainer,
  DropDown,
  ListItem,
  ListItems,
  OvalDiv,
} from "./style";
import { FaCaretSquareDown, FaCaretSquareUp } from "react-icons/fa";
import { Oval } from "react-loading-icons";
import { ButtonProps } from "../../types/types";

const PrimaryButton: React.FC<ButtonProps> = ({
  width,
  color,
  background,
  loading,
  leftIconName,
  rightIconName,
  leftIcon,
  rightIcon,
  icon,
  onClick,
  title,
  border,
  isLoading,
  item,
  action,
  options,
}) => {
  const [openDrop, setOpenDrop] = useState<boolean>(false);
  const handleGeneralClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick(item?.id);
    setOpenDrop(!openDrop);
  };

  return (
    <>
      <ButtonWrapper
        width={width}
        color={color}
        background={background}
        type="submit"
        onClick={handleGeneralClick}
        border={border}
      >
        {isLoading ? (
          <OvalDiv>
            <Oval stroke="#ffffff" fill="white" width={24} height={24} />
          </OvalDiv>
        ) : (
          <>
            {leftIcon && <LeftIconContainer>{leftIconName}</LeftIconContainer>}
            {title}
            {rightIcon && (
              <IconContainer>
                {action ? (
                  <>{openDrop ? <FaCaretSquareUp /> : <FaCaretSquareDown />}</>
                ) : (
                  rightIconName
                )}
              </IconContainer>
            )}
          </>
        )}

        {openDrop && (
          <>
            <DropDown>
              <ListItems>
                {options?.map((option) => (
                  <ListItem key={option.id} onClick={option.action}>
                    {option.title}
                  </ListItem>
                ))}
              </ListItems>
            </DropDown>
          </>
        )}
      </ButtonWrapper>
    </>
  );
};

export default PrimaryButton;
