import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import { generateHeader } from "../../utils/generateHeaders";

const BASE_URL = process.env.REACT_APP_BASE_URL;

interface DataTypeForTransactions {
  BatchId: string;
  Amount: number;
  Neftcharge: number;
  FcmbCharge: number;
  Vat: number;
  InitiatingBranchCode: string;
  Count: number;
  ApprovalStatus: string;
  ProcessingStatus: string;
  CreatedBy: string;
  ApprovedBy: string;
  IsChargesDebited: boolean;
  IsPrincipalAmountDebited: boolean;
  PostingBatchId: string;
}
interface BankAggregateType {
  BankCode: string;
  Amount: number;
  FcmbCharges: number;
  NibssCharges: number;
  Vat: number;
}
interface SIType {
  SolId: string;
  SiFrequency: string;
  IfHoliday: string;
  EndDate: string;
  DrAccountNo: string;
  crAccountNo: string;
  BankName: string;
  Amount: number;
  BeneficiaryName: string;
  SenderName: string;
  ImmediateTransfer: string;
  Si: string;
  StopSi: string;
  No_Of_Time: number;
  No_Of_Time_Exec: number;
  Entry_User_Id: string;
  ProfileDate: string;
  TranId: string;
  ChargeAccount: string;
}
export interface ResponseDataType {
  responseDescription: string;
  data: {
    responseCode: string;
    responseDescription: string;
    status: boolean;
    data: DataTypeForTransactions[];
  };
}

interface ResponseTypeForBankAgg {
  responseDescription: string;
  data: {
    responseCode: string;
    status: boolean;
    data: BankAggregateType[];
  };
}
interface ResponseTypeForSI {
  responseDescription: string;
  data: {
    data: SIType[];
  };
}

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,

    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;

      headers.set("Content-Type", "application/json");
      headers.set("status", "true");
      headers.set("accept", "text/plain");
      headers.set("Authorization", `Bearer ${token}`);

      const generatedHeaders = generateHeader();
      headers.set("X-Frame-Options", "DENY");
      headers.set("X-Content-Type-Options", "nosniff");
      headers.set("Cross-Origin-Opener-Policy", "same-origin");
      headers.set(
        "Content-Security-Policy",
        "frame-ancestors 'self' X-Frame-Options: DENY"
      );

      headers.set("x-token", generatedHeaders["x-token"]);
      headers.set(
        "Ocp-Apim-Subscription-Key",
        generatedHeaders["Ocp-Apim-Subscription-Key"] as string
      );
      headers.set("Ocp-Apim-Trace", "true");
      headers.set("UTCTimestamp", generatedHeaders.UTCTimestamp);
      headers.set("Client_ID", generatedHeaders["Client_ID"]);
    },
  })(args, api, extraOptions);

  const newResponse: any = {
    ...baseResult,
  };
  const errorCode = newResponse?.data?.statusCode;
  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const reportsApi = createApi({
  reducerPath: "reportsApi",
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    getTransactionReport: builder.mutation<
      ResponseDataType,
      { StartDate: string; EndDate: string }
    >({
      query: (params) => ({
        url: "reports/transactions-report",
        method: "GET",
        params,
      }),
      transformResponse: (response: ResponseDataType, meta) => response,
    }),

    // get bank aggregate report
    getBankAggregateReport: builder.mutation<
      ResponseTypeForBankAgg,
      { StartDate: string; EndDate: string }
    >({
      query: (params) => ({
        url: "reports/clearing/bank-aggregate-report",
        method: "GET",
        params,
      }),
    }),

    // get SI report
    getSIReport: builder.mutation<
      ResponseTypeForSI,
      { StartDate: string; EndDate: string }
    >({
      query: (params) => ({
        url: "reports/si-transactions-report",
        method: "GET",
        params,
      }),

      transformResponse: (response: ResponseTypeForSI, meta) => response,
    }),

    // get clearing SI report
    getClearingSIReport: builder.mutation<
      ResponseTypeForSI,
      { StartDate: string; EndDate: string }
    >({
      query: (params) => ({
        url: "reports/clearing/si-transactions-report",
        method: "GET",
        params,
      }),

      transformResponse: (response: ResponseTypeForSI, meta) => response,
    }),

    // get branch Transaction Report
    getBranchTransactionsReport: builder.mutation<
      ResponseDataType,
      { StartDate: string; EndDate: string; BranchCode: string }
    >({
      query: (params) => ({
        url: "reports/get-transactions-by-branch",
        method: "GET",
        params,
      }),
      transformResponse: (response: ResponseDataType, meta) => response,
    }),
  }),
});

export const {
  useGetTransactionReportMutation,
  useGetClearingSIReportMutation,
  useGetSIReportMutation,
  useGetBankAggregateReportMutation,
  useGetBranchTransactionsReportMutation,
} = reportsApi;
