import { combineReducers } from "@reduxjs/toolkit";
import { authApi } from "../services/auth/authService";
import { StaffDataReducer } from "../services/auth/authSlice";
import { rolesApi } from "../services/user-management/assignRoles";
import { chartApi } from "../services/activities/chartService";
import { transactionsApi } from "../services/activities/transactionStats";
import { chargeApi } from "../services/charges/chargeService";
import openSlice from "./slices/stateSlice";
import childToParent from "./slices/childToParent";
import { standingApi } from "../services/standingInstructions/standingService";
import { postStandingInstruction } from "../services/standingInstructions/createService";
import postSlice from "./slices/fileupload/fileuploadslice";
import approveTransactionDetails from "./slices/fileupload/approveTransaction";
import declineTransactionDetails from "./slices/fileupload/declineTransaction";
import batchGet from "./slices/fileupload/getBatch";
import initiateTransactionDetails from "./slices/fileupload/initiateTransaction";
import getDeclinedTransactions from "./slices/fileupload/getDeclinedTransaction";
import getApprovedTransaction from "./slices/fileupload/getApprovedTransaction";
import getInitiatedTransactionsDetails from "./slices/fileupload/initiatedTransactions";
import fetchSummary from "./slices/fileupload/uploadedSummary";
import { reportsApi } from "../services/reports/reportService";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reportTableSlice from "./slices/reportTableSlice";

const persistConfig = {
  key: "StaffDataReducer", // This is a unique identifier for your slice
  storage, // The storage engine to use (e.g., localStorage or AsyncStorage for React Native)
};
const persistedReducer = persistReducer(persistConfig, StaffDataReducer);
export default combineReducers({
  openSlice,
  reportTableSlice,
  postSlice,
  initiateTransactionDetails,
  approveTransactionDetails,
  declineTransactionDetails,
  getDeclinedTransactions,
  getApprovedTransaction,
  batchGet,
  fetchSummary,
  getInitiatedTransactionsDetails,
  StaffDataReducer: persistedReducer,
  [authApi.reducerPath]: authApi.reducer,
  [chartApi.reducerPath]: chartApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [chargeApi.reducerPath]: chargeApi.reducer,
  [transactionsApi.reducerPath]: transactionsApi.reducer,
  [standingApi.reducerPath]: standingApi.reducer,
  [postStandingInstruction.reducerPath]: postStandingInstruction.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
});
