import { HashLoader } from "react-spinners";
import styled from "styled-components";

import { CustomHashLoaderProps } from "../../types/types";

const HashLoaderDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

export function CustomHashLoader({ color, size }: CustomHashLoaderProps) {
  return (
    <HashLoaderDiv>
      <HashLoader color={color} size={size} />
    </HashLoaderDiv>
  );
}

CustomHashLoader.defaultProps = {
  color: "5c2684",
  size: 60,
};
