import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { errorFormatter } from "../../../utils/errorFormatter";


const BASE_URL = process.env.REACT_APP_BASE_URL;
const END_POINT = `transactions/initiated`;

export const getInitiatedTransactions = createAsyncThunk<any>(
  "data/getTransactions",
  async (payload, { rejectWithValue }) => {
    try {
      const localInfo = localStorage.getItem("staffInfo");
      const tokenValue = JSON.parse(localInfo ?? "{}")?.accessToken;

      const headers = {
        Authorization: `Bearer ${tokenValue}`,
      };

      const response = await axios.get(`${BASE_URL}${END_POINT}`, { headers });
      if (response?.data?.statusCode === 401) {
        localStorage.clear();
        window.location.href = "/login";
      }
      return response.data;
    } catch (error: any) {
      console.log(error)
      error?.response?.data?.message
        ? toast.error(error?.response?.data?.message)
        : toast.error(error?.response?.data?.responseDescription);

      return rejectWithValue(error);
    }
  },
);

// slice to get initiatedTransactions
const getInitiatedTransactionsDetails = createSlice({
  name: "initiated",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInitiatedTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.data = null;
      })
      .addCase(getInitiatedTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload?.data;
      })
      .addCase(getInitiatedTransactions.rejected, (state: any, action) => {
        let message = errorFormatter(action.payload);
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default getInitiatedTransactionsDetails.reducer;
