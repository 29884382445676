import { useEffect, useState } from "react";
import DateComponent from "../../../components/DatePicker";
import { Container, Status } from "./style";
import PrimaryButton from "../../../components/Button";
import { useInitiateTransactionMutation } from "../../../services/standingInstructions/standingService";
import toast from "react-hot-toast";
import { useAppSelector } from "../../../store/hooks";
import ContentLayout from "../../../layout/Content";
import Header from "../../../components/Header";
import { HeaderText, PuffDiv } from "../Supervisor/style";
import { TableDiv } from "../transactions/style";
import PrimaryTable from "../../../components/Table";
import { PuffLoader } from "react-spinners";
import { useGetBulkPendingMutation } from "../../../services/standingInstructions/standingService";
import { useNavigate } from "react-router-dom";
import { sortTableData } from "../../../utils/sortByDate";

const PendingBulk = () => {
  const navigate = useNavigate();
  const token: any = useAppSelector(
    (state) => state.StaffDataReducer?.staffInfo?.accessToken
  );
  const [pendingBulk, dataMutation] = useGetBulkPendingMutation();
  const [initiateTransaction, initiateMutation] =
    useInitiateTransactionMutation();
  const [selectedBatchId, setBatchId] = useState<string>("");

  const [bulkData, setBulkData] = useState<any>([]);

  const handleGetPendinBulk = async () => {
    try {
      const response = await pendingBulk().unwrap();
      if (response?.responseCode === "00") {
        let dd = [...response?.data];
        let sortedArr = dd.sort(sortTableData);
        setBulkData(sortedArr);
      }
    } catch (error) {}
  };
  useEffect(() => {
    handleGetPendinBulk();
  }, []);

  const handleView = async () => {
    navigate(`/transaction/view-batch/${selectedBatchId}`);
  };

  const handleSubmitTransaction = async () => {
    try {
      const initiateData = {
        batchId: selectedBatchId,
        transactionIds: ["0"],
      };
      const response = await initiateTransaction(initiateData).unwrap();
      if (response?.responseCode === "00") {
        handleGetPendinBulk();
        toast.success(response?.responseDescription);
      } else if (response?.responseCode === "99") {
        toast.error(response?.responseDescription);
      }
    } catch (error: any) {
      toast(error?.responseDescription);
      console.log("eeee", error);
    }
  };

  const btnOptions = [
    { id: 1, title: "View", action: handleView },
    { id: 2, title: "Initiate", action: handleSubmitTransaction },
  ];

  const ivalidBtnOptions = [{ id: 1, title: "View", action: handleView }];

  const bulkHeader = [
    { title: "S/N", id: 1 },
    { title: "BatchId", id: 2 },
    { title: "Debit Amount", id: 3 },
    { title: "Credit Amount", id: 4 },
    { title: "Transaction Count", id: 5 },
    { title: "Created By", id: 6 },
    { title: "Date", id: 8 },
    { title: "Status", id: 9 },
    { title: "Action", id: 10 },
  ];

  const bulkDataBody = bulkData?.map((data: any, index: number) => [
    index + 1,
    data.batchId,
    new Intl.NumberFormat().format(data.totalDebitAmount),
    new Intl.NumberFormat().format(data.totalCreditAmount),
    data.transactionCount,
    data.createdBy?.split("@")[0],
    data.createdOn?.substring(0, 10),
    <Status
      key={data.id}
      color={data?.transactionStatus === "VALID" ? "#01B272" : "#C43C20"}
      backgroundColor={
        data?.transactionStatus === "VALID"
          ? "rgba(1, 178, 114, 0.2)"
          : "rgba(231, 175, 164, 0.3)"
      }
    >
      {data?.transactionStatus}
    </Status>,
    <PrimaryButton
      background={"#fff"}
      color={"#5c2684"}
      width={"110px"}
      title={"Action"}
      icon={""}
      rightIcon={true}
      action={true}
      openDrop
      loading={false}
      leftIconName={<></>}
      rightIconName={<></>}
      onClick={() => {
        setBatchId(data?.batchId);
      }}
      leftIcon={false}
      border={"1px solid #5c2684"}
      options={data?.isValid === true ? btnOptions : ivalidBtnOptions}
    />,
  ]);

  return (
    <>
      <Header title="View Transactions" />
      <ContentLayout>
        <Container>
          <HeaderText>Bulk Transactions</HeaderText>
          {/* <DateWrap>
            <DateComponent
              showModal={false}
              onClosed={() => {}}
              value={selectedStartDate}
              handleDateChange={(e: any) =>
                setSelectedStartDate(e.target.value)
              }
              selectDate={setSelectedStartDate}
              label={"Start date"}
            />

            <DateComponent
              showModal={false}
              onClosed={() => {}}
              value={selectedEndDate}
              handleDateChange={(e: any) => setSelectedEndDate(e.target.value)}
              selectDate={setSelectedEndDate}
              label={"End date"}
            />
          </DateWrap>
          <ButtonWrapper>
            <PrimaryButton
              background={"#5c2684"}
              color={"#fff"}
              width={"150px"}
              title={"Search"}
              loading={false}
              isLoading={isLoading}
              leftIconName={<></>}
              rightIconName={<></>}
              onClick={handleFilter}
              border={""}
            />
          </ButtonWrapper> */}

          {dataMutation.isLoading || initiateMutation.isLoading ? (
            <PuffDiv>
              <PuffLoader />
            </PuffDiv>
          ) : (
            <>
              {bulkData.length !== 0 && (
                <TableDiv>
                  <PrimaryTable
                    header={bulkHeader}
                    body={bulkDataBody}
                    arrOfObject={true}
                  />
                </TableDiv>
              )}
            </>
          )}
        </Container>
      </ContentLayout>
    </>
  );
};
export default PendingBulk;
