import {
  Container,
  Department,
  Flexflow,
  ImageC,
  ImageContainer,
  LeftContainer,
  LeftHeader,
  RightContainer,
  UserContainer,
  Username,
} from "./style";

const Header = ({ title = "Dashboard" }) => {
  const localInfo = JSON.parse(localStorage.getItem("staffInfo") ?? "");
  let role = localInfo?.user?.role;
  let username = localInfo?.user?.displayName;
  return (
    <Container>
      <LeftContainer>
        <LeftHeader>{title}</LeftHeader>
      </LeftContainer>
      <RightContainer>
        <UserContainer>
          <ImageContainer>
            <ImageC src="/images/user.png" alt="users" />
          </ImageContainer>
          <Flexflow>
            <Username>{username}</Username>
            <Department>{role}</Department>
          </Flexflow>
        </UserContainer>
      </RightContainer>
    </Container>
  );
};

export default Header;
