import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Header from "../../../components/Header";
import ContentLayout from "../../../layout/Content";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg";
import {
  Container,
  HeaderText,
  BottomContainer,
  TableContainer,
  ButtonWrap,
  DetailsWrap,
  IconWrap,
  LeftD,
  RightD,
  FieldWrapD,
  Text,
  PuffDiv,
  TopTitle,
  Top,
} from "./style";
import PrimaryButton from "../../../components/Button";
import { PuffLoader } from "react-spinners";
import PrimaryTable from "../../../components/Table";
import toast from "react-hot-toast";

import {
  usePendingChargeApprovalMutation,
  useApproveChargeMutation,
} from "../../../services/standingInstructions/standingService";
import GeneralModal from "../../../components/Modal/GeneralModal";

const ChargeApproval = () => {
  const [pendingList, setpendingList] = useState<any>([]);
  const [id, setId] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [veiwData, setVeiwData] = useState<any>();
  const [pendingCharge, pendingUserMutation] =
    usePendingChargeApprovalMutation();
  const [approveCharge, approveUserMutation] = useApproveChargeMutation();

  const handleGetPendingList = async () => {
    try {
      const response = await pendingCharge().unwrap();

      if (response.responseCode === "00") {
        toast.success(response?.responseDescription);
        setpendingList(response?.data);
      }
    } catch (err: any) {
      toast.error(err?.data?.responseDescription);
    }
  };
  useEffect(() => {
    handleGetPendingList();
  }, []);

  const handleView = (id: number) => {
    setId(id);
    const findData = pendingList?.find((data: any) => data.Id === id);
    setVeiwData(findData);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleApprove = async () => {
    try {
      const requiredData = {
        id: id,
        isApproved: true,
      };

      const response = await approveCharge(requiredData).unwrap();
      if (response.responseCode === "00") {
        toast.success(response?.responseDescription);
        setpendingList([]);
        setOpenModal(false);
        handleGetPendingList();
      }
    } catch (error: any) {
      toast.error(error?.data?.responseDescription);
    }
  };

  const handleDecline = async () => {
    try {
      const requiredData = {
        id: id,
        isApproved: false,
      };
      const response = await approveCharge(requiredData).unwrap();

      if (response.responseCode === "00") {
        toast.success(response?.responseDescription);
        setpendingList([]);
        setOpenModal(false);
        handleGetPendingList();
      }
    } catch (error: any) {
      toast.error(error?.data?.responseDescription);
    }
  };
  const header = [
    {
      id: 1,
      title: "S/N",
    },
    {
      id: 2,
      title: "UserName",
    },

    {
      id: 3,
      title: "Role Name",
    },
    {
      id: 4,
      title: "Status",
    },
    {
      id: 5,
      title: "Created By",
    },
    {
      id: 6,
      title: "Last Seen",
    },
    {
      id: 6,
      title: "Action",
    },
  ];

  const dataBody = pendingList?.map((item: any, index: number) => [
    index + 1,
    item?.ChargeAmount,
    item?.FCMBIncome,
    item?.NIBSSIncome,
    item?.NewFCMBIncome,
    item?.NewNIBSSIncome,
    item?.NewVat,
    item?.CreatedBy?.slice(0, item?.CreatedBy.indexOf("@")),
    <>
      <PrimaryButton
        key={item.username}
        background={"#fff"}
        color={"#5c2684"}
        width={"100%"}
        title={"View"}
        icon={""}
        rightIcon={true}
        loading={false}
        leftIconName={<></>}
        rightIconName={<></>}
        onClick={() => handleView(item?.Id)}
        leftIcon={false}
        border={"1px solid #5c2684"}
        action={false}
        options={undefined}
        item={item}
      />
    </>,
  ]);

  return (
    <>
      <Header title="Charge Management" />
      <ContentLayout>
        <Container>
          <BottomContainer>
            <HeaderText>Charges</HeaderText>
          </BottomContainer>

          <TableContainer>
            {pendingUserMutation?.isLoading ? (
              <PuffDiv>
                <PuffLoader />
              </PuffDiv>
            ) : (
              <PrimaryTable
                header={header}
                body={dataBody}
                arrOfObject={true}
              />
            )}
          </TableContainer>
        </Container>

        {openModal && (
          <>
            <GeneralModal general>
              <Top>
                <TopTitle>Other Information</TopTitle>
                <IconWrap>
                  <CloseIcon onClick={handleClose} />
                </IconWrap>
              </Top>

              <FieldWrapD>
                <DetailsWrap>
                  <LeftD>
                    <Text>Minimum Transaction Amount</Text>{" "}
                  </LeftD>
                  <RightD>
                    <Text>
                      {" "}
                      {new Intl.NumberFormat().format(
                        veiwData?.MinimumTransactionAmount
                      )}
                    </Text>{" "}
                  </RightD>
                </DetailsWrap>
                <DetailsWrap>
                  <LeftD>
                    <Text>Maximum Transaction Amount</Text>{" "}
                  </LeftD>
                  <RightD>
                    <Text>
                      {new Intl.NumberFormat().format(
                        veiwData.MaximumTransactionAmount
                      )}
                    </Text>{" "}
                  </RightD>
                </DetailsWrap>
                <DetailsWrap>
                  <LeftD>
                    <Text>New Charge Amount</Text>{" "}
                  </LeftD>
                  <RightD>
                    <Text> {veiwData?.NewChargeAmount}</Text>{" "}
                  </RightD>
                </DetailsWrap>
                <DetailsWrap>
                  <LeftD>
                    <Text>New Minimum Transaction Amount</Text>{" "}
                  </LeftD>
                  <RightD>
                    <Text>
                      {" "}
                      {new Intl.NumberFormat().format(
                        veiwData?.NewMinimumTransactionAmount
                      )}
                    </Text>{" "}
                  </RightD>
                </DetailsWrap>
                <DetailsWrap>
                  <LeftD>
                    <Text>New Maximum Transaction Amount</Text>{" "}
                  </LeftD>
                  <RightD>
                    <Text>
                      {" "}
                      {new Intl.NumberFormat().format(
                        veiwData?.NewMaximumTransactionAmount
                      )}
                    </Text>{" "}
                  </RightD>
                </DetailsWrap>
              </FieldWrapD>
              <ButtonWrap>
                <PrimaryButton
                  background={"#E21A1A"}
                  color={"#ffffff"}
                  width={140}
                  title={"Decline"}
                  icon={""}
                  loading={false}
                  leftIconName={<></>}
                  rightIconName={<></>}
                  onClick={handleDecline}
                  leftIcon={false}
                  border={"1px solid #E21A1A"}
                />

                <PrimaryButton
                  background={"green"}
                  color={"#ffffff"}
                  width={140}
                  title={"Approve"}
                  icon={""}
                  loading={false}
                  leftIconName={<></>}
                  rightIconName={<></>}
                  onClick={handleApprove}
                  leftIcon={false}
                  border={"1px solid green"}
                />
              </ButtonWrap>
            </GeneralModal>
          </>
        )}
      </ContentLayout>
    </>
  );
};

export default ChargeApproval;
