import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ProtectedProps {
  isVerified: any;
  children: any;
}

const Protected = ({ isVerified, children }: ProtectedProps) => {
  const navigate = useNavigate();

  //const [loading, setLoading] = useState(true);

  useEffect(() => {
    const dataJson =
      typeof isVerified === "string" ? JSON.parse(isVerified) : isVerified;

    const isUserAuthenticated = () => {
      return !!dataJson?.accessToken; // Use !! to convert to a boolean
    };

    if (!isUserAuthenticated()) {
      navigate("/login");
    }

    // setLoading(false); // Mark loading as complete
  }, [isVerified, navigate]);

  if (isVerified && isVerified.accessToken) {
    return children;
  } else {
    // Handle the case when isVerified is not available
    navigate("/login");
    // return <div>Authentication data not available</div>;
  }
};

export default Protected;
