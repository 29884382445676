import { config } from "../config";
import CryptoJS from "crypto-js";

export const generateHeader = () => {
  const dateToUse = new Date();
  const UTCTimestamps = dateToUse.toISOString().replace("Z", "");
  const dateInToken = UTCTimestamps.replace("T", "")
    .replace(":", "")
    .replace(":", "")
    .substring(0, UTCTimestamps.length - 7);
  const shaOneEncrypt = CryptoJS.SHA512(
    dateInToken + config.client_id + config.xtoken_password
  );
  const apiHeader = {
    "x-token": shaOneEncrypt.toString(CryptoJS.enc.Hex),
    Client_ID: config.client_id,
    "Ocp-Apim-Subscription-Key": config.subscription_key_value,
    UTCTimestamp: UTCTimestamps,
  };
  return apiHeader;
};

const generatedHeaders = generateHeader();

export const defaultHeaders = {
  "Content-Type": "application/json",
  status: "true",
  accept: "text/plain",
  "X-Frame-Options": "DENY",
  "X-Content-Type-Options": "nosniff",
  "Cross-Origin-Opener-Policy": "same-origin",
  "Content-Security-Policy": "frame-ancestors 'self' X-Frame-Options: DENY",
  "x-token": generatedHeaders["x-token"],
  "Ocp-Apim-Subscription-Key": generatedHeaders["Ocp-Apim-Subscription-Key"],
  "Ocp-Apim-Trace": "true",
  UTCTimestamp: generatedHeaders.UTCTimestamp,
  Client_ID: generatedHeaders["Client_ID"],
};
