import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Header from "../../../components/Header";
import ContentLayout from "../../../layout/Content";
import {
  BottomContainer,
  ButtonDiv,
  ButtonWrap,
  Container,
  DataDiv,
  DateWrap,
  HeaderText,
  Label,
  Name,
  NameContainer,
  PuffDiv,
  Status,
  TableContainer,
  TableDiv,
  TopContainer,
} from "./style";
import PrimaryButton from "../../../components/Button";
import { PuffLoader } from "react-spinners";
import PrimaryTable from "../../../components/Table";
import { ReactComponent as SuccessIcon } from "../../../assets/svg/success.svg";
import Success from "../../../components/Modal/Success";
import toast from "react-hot-toast";
import { DropDownC } from "../../../components/Dropdown/index";
import {
  useActivateUserMutation,
  useAssignRoleMutation,
  useDeactivateUserMutation,
  useGetAllUsersQuery,
  useGetRolesQuery,
  useGetUserMutation,
} from "../../../services/user-management/assignRoles";
import SearchCard from "../../../components/Cards/SearchCard";
import Button from "../../../components/Button";
import PrimaryInput from "../../../components/Input";
import { SearchCardText } from "../../../components/SearchText";
import {
  usePendingUserApprovalMutation,
  useApproveUserMutation,
} from "../../../services/standingInstructions/standingService";

const UserApproval = () => {
  const [pendingList, setpendingList] = useState<any>([]);
  const [username, setUsername] = useState<string>("");
  const [pendingUser, pendingUserMutation] = usePendingUserApprovalMutation();
  const [approveUser, approveUserMutation] = useApproveUserMutation();

  const handleGetPendingList = async () => {
    try {
      const response = await pendingUser().unwrap();
      if (response.responseCode === "00") {
        toast.success(response?.responseDescription);
        setpendingList(response?.data);
      }
    } catch (err: any) {
      toast.error(err?.data?.responseDescription);
    }
  };
  useEffect(() => {
    handleGetPendingList();
  }, []);

  const handleAction = async (username: string) => {
    setUsername(username);
  };

  const handleApprove = async () => {
    try {
      const requiredData = {
        username: username,
        isApproved: true,
      };
      console.log(requiredData);
      const response = await approveUser(requiredData).unwrap();

      if (response.responseCode === "00") {
        toast.success(response?.responseDescription);
        handleGetPendingList();
      }
    } catch (error: any) {
      toast.error(error?.data?.responseDescription);
    }
  };

  const handleDecline = async () => {
    try {
      const requiredData = {
        username: username,
        isApproved: false,
      };
      const response = await approveUser(requiredData).unwrap();

      if (response.responseCode === "00") {
        toast.success(response?.responseDescription);
        handleGetPendingList();
      }
    } catch (error: any) {
      toast.error(error?.data?.responseDescription);
    }
  };

  const userActionList = [
    {
      id: 1,
      title: "Approve",
      action: handleApprove,
    },
    {
      id: 2,
      title: "Decline",
      action: handleDecline,
    },
  ];

  const header = [
    {
      id: 1,
      title: "S/N",
    },
    {
      id: 2,
      title: "UserName",
    },

    {
      id: 3,
      title: "Role Name",
    },
    {
      id: 4,
      title: "Created By",
    },
    {
      id: 5,
      title: "Created On",
    },
    {
      id: 6,
      title: "Last Seen",
    },
    {
      id: 6,
      title: "Action",
    },
  ];

  const dataBody = pendingList?.map((item: any, index: number) => [
    index + 1,
    item?.username,

    item?.rolename,
    item?.createdby,
    item?.CreatedOn?.slice(0, 10),
    item?.lastlogin?.slice(0, 10),
    <>
      <PrimaryButton
        key={item.username}
        background={"#fff"}
        color={"#5c2684"}
        width={"100%"}
        title={"Action"}
        icon={""}
        rightIcon={true}
        loading={false}
        leftIconName={<></>}
        rightIconName={<></>}
        onClick={() => handleAction(item?.username)}
        leftIcon={false}
        border={"1px solid #5c2684"}
        action={true}
        options={userActionList}
        item={item}
      />
    </>,
  ]);

  return (
    <>
      <Header title="User Approval" />
      <ContentLayout>
        <Container>
          <HeaderText> User</HeaderText>

          <TableContainer>
            {pendingUserMutation?.isLoading ? (
              <PuffDiv>
                <PuffLoader />
              </PuffDiv>
            ) : (
              <TableDiv>
                <PrimaryTable
                  header={header}
                  body={dataBody}
                  arrOfObject={true}
                />
              </TableDiv>
            )}
          </TableContainer>
        </Container>
      </ContentLayout>
    </>
  );
};

export default UserApproval;
